<script>
import PlatformConfigurationForm from "@/views/menu/configurations/platform-configuration/platformConfigurationForm";
import Component, { mixins } from "vue-class-component";
import { StrUtility } from "@/lib/StrUtility";
import { Utility } from "@/lib/Utility";

@Component({
  inject: {
    platformconfigurationService: "platformConfigurationService",
    toastService: "toastService",
  },
  watch: {
    category: {
      immediate: false,
      handler: function (newVal) {
        if (newVal) this.$router.go();
      },
    },
  },
})
export default class PlatformConfigurationEdit extends mixins(
  PlatformConfigurationForm
) {

  formId = "edit-platformconfiguration-form";
  gtiCategories = [];

  get fieldColumns() {
    return 2;
  }

  get needTestGTIConnection(){
    return this.model.enableGTI != undefined;
  }

  get actions() {

   let items = [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save,
      },
    ];

    if(this.needTestGTIConnection){
      items.unshift(
        {
         id: "testgti",
         type: "submit",
         text: this.translations.labels.button_test_gti,
         leftIcon: "mdi mdi-phone-settings",
         handle: this.testGti,
        }
      );
    }

    return items;
  }

  get category() {
    return this.$route.query.category;
  }

  async testGti(){

    const domain = this.model.gTIDomainName;
    const authCallMeToken = this.model.tokenAuthModuloCallMeGTI;
    const presenceToken = this.model.tokenPresenceGTI;

    const gtiEndpoint = `https://${domain}/callme/presence/${presenceToken}/roster`;
    const authCallMeHeader = {"X-Auth-Callme":authCallMeToken};

    try {

        const response = await fetch(gtiEndpoint , {
        method:"GET",
        headers: authCallMeHeader
        });

        if(response.ok){
          this.toastService.success(this.translations.success.gticonfiguration_test);
        } else {
          this.toastService.error(this.translations.errors.gticonfiguration_test);
        }

    } catch (error) {
        this.toastService.error(this.translations.errors.gticonfiguration_test);
    }

  }

  async save(action, passesValidation) {
    this.errors = [];

    if (!passesValidation) {
      return;
    }

    try {
      const retList = this.returnListObjectCreation();
      await this.platformconfigurationService.update(retList);

      this.toastService.success(
        this.translations.success.platformconfiguration_update
      );
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  returnListObjectCreation() {
    const retList = [];
    const localizations = {};

    this.modelRead.forEach((item) => {
      if (item.valueType.startsWith("multilang:")) {
        if (typeof localizations[item.id] === "undefined") {
          localizations[item.id] = {
            id: item.id,
            name: item.name,
            value: {},
            valueType: item.valueType,
            options: item.options,
          };
        }
        if (this.model?.localizations && this.model.localizations.length > 0) {
          for (const loc of this.model.localizations) {
            if (typeof loc[StrUtility.lowerFirst(item.name)] !== "undefined") {
              localizations[item.id].value[loc.idLanguage] =
                loc[StrUtility.lowerFirst(item.name)];
            }
          }
        }
        return;
      }
      let newModelValue = Utility.toStringConverter(
        item.valueType,
        this.model[StrUtility.lowerFirst(item.name)]
      );
      retList.push({
        id: item.id,
        name: item.name,
        value: newModelValue ?? "",
        valueType: item.valueType,
        options: item.options,
      });
    });
    for (const locInd in localizations) {
      const loc = localizations[locInd];
      loc.value = JSON.stringify(loc.value);
      retList.push(loc);
    }

    return retList;
  }

  getFormTitle() {
    return this.translations.pageTitles.platformConfigurations_edit;
  }

  async afterCreate() {
    this.modelRead = await this.platformconfigurationService.list(
      this.category
    );

    // I separate the platformConfiguration by project and transform the array data of the elements into individual properties of the model
    this.modelDataSourceManipulation();
    this.isReady = true;
  }

}
</script>
<style lang="scss">
#edit-platformconfiguration-form {
  .v-messages__message {
    white-space: pre-wrap;
  }
}
</style>