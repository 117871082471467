import EntityForm from "@/mixins/EntityForm";
import Component, { mixins } from "vue-class-component";
import { StrUtility } from "@/lib/StrUtility";
import { Utility } from "@/lib/Utility";

@Component({
  inject: {
    languageService: "languageService",
  },
})
export default class PlatformConfigurationForm extends mixins(EntityForm) {
  model = {};
  modelRead = [];
  projectList = [];
  platformLanguages = [];

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get languages() {
    return this.platformLanguages.map(l => {
      return {
        id: l.code,
        description: l.description,
      };
    });
  }

  get elements() {
    //cycle the response and foreach project create a different fieldset to embed in an accordion
    if (!this.modelRead)
      return [];

    let result = [];

    this.projectList.forEach((proj) => {
      if (proj) {
        const elements = proj?.elements?.sort((a, b) => {
          a?.displayOrder - b?.displayOrder;
        }) ?? [];

        const allElements = elements.filter(ele => !(ele?.type ?? "").startsWith("multilang:")).map((ele) => {
          const jsonOptions = this.deserializeOptions(ele?.options);
          const hintDescription = this.showHintDescription(ele);

          const element = {
            id: ele.name,
            label: this.translations.labels[`platformConfiguration_${ele.name}`] ?? ele.name,
            type: ele.type,
            require: true,
            items: ele.type == "select" ? jsonOptions?.elements : null,
            hint: hintDescription,
            persistentHint: true
          };

          return element;
        });
        const localizedElements = elements.filter(ele => (ele?.type ?? "").startsWith("multilang:")).map((ele) => {
          const jsonOptions = this.deserializeOptions(ele?.options);

          const element = {
            id: ele.name,
            label: this.translations.labels[`platformConfiguration_${ele.name}`] ?? ele.name,
            type: ele.type.replace("multilang:", ""),
            require: true,
            items: ele.type == "select" ? jsonOptions.elements : null,
            hint: ele.description,
            persistentHint: true
          };
          return element;
        });
        if (localizedElements.length > 0) {
          allElements.push({
            id: "localizations",
            type: "localizations",
            header: this.translations.labels.form_localizations,
            languages: this.languages,
            resetColumns: true,
            elements: localizedElements,
          });
        }
        result.push({
          legend: proj.project,
          type: "fieldset",
          group: "default",
          collapsible: true,
          open: true,
          resetColumns: true,
          elements: allElements
        });
      }
    });

    return result;
  }

  get helpLink() {
    let category = this.$route.query.category;

    switch(category){
      case "gti":
        return this.translations.pageHelp.configuration.gti;
      case "security":
        return this.translations.pageHelp.configuration.security;
      case "privacy":
        return this.translations.pageHelp.configuration.privacy;
      default:
        return this.translations.pageHelp.configuration.platform;
    }
  }

  deserializeOptions(options) {
    if (options) {
      const deserializedOptions = options.replace(/\\/g, "");
      return JSON.parse(deserializedOptions);
    }

    return options;
  }

  showHintDescription(ele) {
    if (ele.name != "UserDataTracking" || ele.name == "UserDataTracking" && ele.value == 2) {
      return ele.description;
    }
    return null;
  }

  modelDataSourceManipulation() {
    this.model.localizations = [];
    const localizations = [];
    this.modelRead.forEach((item) => {
      this.populateProjectList(item);
      // From array object to model property
      let element = {};

      element.id = item.id;
      element.type = Utility.toFormType(item.valueType, item.value);
      element.name = StrUtility.lowerFirst(item.name);
      element.description = item.description;
      element.displayOrder = item.displayOrder;
      element[element.name] = element.type == "file" ? Utility.getFilePath(item.value) : item.value;
      element.value = element.type == "file" ? Utility.getFilePath(item.value) : item.value;
      element.options = item.options;

      // I insert the property in the form data association model
      if (!item.valueType.startsWith("multilang:")) {
        this.model[element.name] = Utility.fromStringConverter(item.valueType, item.value);
      } else {
        localizations.push({
          name: StrUtility.lowerFirst(item.name),
          value: Utility.fromStringConverter(item.valueType, item.value)
        });
      }

      // insert the element in the project array
      let project = this.projectList.filter(proj => proj.project == item.project)[0];
      project.elements.push(element);
      project[element.name] = item.value;
    });

    for (const lang of this.languages) {
      const locFields = { idLanguage: lang.id };
      for (const { name, value } of localizations) {
        if (typeof value[lang.id] !== "undefined") {
          locFields[name] = value[lang.id];
        }
      }
      this.model.localizations.push(locFields);
    }
  }

  populateProjectList(item) {
    // If the list does not contain the project related to the element I add it (it will become a fieldset)
    if (this.projectList.filter(proj => proj.project == item.project).length == 0) {
      const project = {
        project: item.project,
        elements: []
      };
      this.projectList.push(project);
    }
  }

  // eslint-disable-next-line no-empty-function
  afterCreate() { }

  async created() {
    this.platformLanguages = await this.languageService.list();
    this.afterCreate();
  }
}